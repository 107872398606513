import React, { useContext } from 'react';
import AuthContext from '../../../contexts/authContext';

//** i18next */
import { useTranslation } from 'react-i18next';

import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';

const DefaultHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(AuthContext);

	const { t } = useTranslation('translation');

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								{t('Hello')}, {user}!
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>

			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
