import React from 'react';

const LogoLoginBroqui = () => {
	return (
		<svg
			width='100'
			height='100'
			viewBox='0 0 100 100'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<rect width='100' height='100' rx='50' fill='#38A388' />
			<path
				d='M40.3497 66.5611C40.3118 66.6557 40.2929 66.7503 40.274 66.8638C40.2551 66.9773 40.2172 67.0719 40.1983 67.1855C38.7982 67.9044 37.19 68.3207 35.5062 68.3207C29.868 68.3207 25.3083 63.742 25.3083 58.1228C25.3083 56.7606 25.5732 55.4551 26.0651 54.2632C22.792 52.5604 20.5405 49.1359 20.5405 45.2005C20.5405 39.5624 25.1191 35.0027 30.7384 35.0027C32.3844 35.0027 33.9169 35.3811 35.2981 36.0811C36.263 29.1375 42.2227 23.7832 49.4501 23.7832C56.8857 23.7832 62.9968 29.4781 63.6779 36.7433C65.305 35.646 67.2538 35.0216 69.3539 35.0216C74.992 35.0216 79.5517 39.6002 79.5517 45.2195C79.5517 49.1737 77.3192 52.5982 74.0271 54.2821C74.519 55.474 74.7839 56.7795 74.7839 58.1417C74.7839 63.7799 70.2053 68.3396 64.5861 68.3396C62.9779 68.3396 61.4454 67.9612 60.0831 67.299C60.0642 67.1855 60.0264 67.053 59.9885 66.9395C59.9507 66.826 59.9318 66.7125 59.8939 66.5989'
				stroke='white'
				strokeWidth='2'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M62.8643 55.057C61.994 56.2111 61.1048 57.422 60.5372 58.6518C59.9507 59.9384 60.3291 61.452 60.3291 62.852C60.3291 65.9738 58.929 68.755 56.7343 70.6281C57.4722 71.1768 57.9452 72.066 57.9452 73.0688C57.9452 74.7526 56.5829 76.1338 54.8801 76.1338C53.1963 76.1338 51.8151 74.7716 51.8151 73.0688C51.8151 73.0309 51.8151 72.9742 51.8151 72.9174C51.2664 73.012 50.6988 73.0688 50.1123 73.0688C49.6582 73.0688 49.2042 73.0309 48.7501 72.9742C48.7501 73.012 48.7501 73.0309 48.7501 73.0688C48.7501 74.7526 47.3879 76.1338 45.6851 76.1338C44.0012 76.1338 42.62 74.7716 42.62 73.0688C42.62 72.1606 43.0174 71.3281 43.6606 70.7795C43.4336 70.9687 41.5605 68.4523 41.4092 68.2064C40.7659 67.1468 40.3118 65.9738 40.0848 64.7629C39.7064 62.7574 40.1226 60.7708 40.4632 58.7842C39.8199 57.6869 38.9496 56.722 38.4955 55.5489C38.0414 54.3759 38.1928 52.8056 39.2523 52.1244C40.1037 51.5758 41.2956 51.7839 42.1281 52.3893C43.1309 53.1272 43.4714 54.83 44.6445 54.5651C44.8526 54.5273 48.1636 52.5974 48.1825 52.8434C47.7852 49.5135 47.4825 46.5809 49.1852 43.497C49.488 42.9483 49.8664 42.3996 50.3772 42.0401C50.888 41.6807 51.5881 41.5104 52.1746 41.7563C53.0827 42.1537 53.3476 43.3078 53.4611 44.2916C53.7639 47.1485 53.9341 50.1189 53.972 52.9569C55.3721 52.9948 56.7154 53.7137 57.51 54.8678C58.4749 53.2218 59.7993 51.8028 61.3697 50.7244C61.8427 50.4027 62.3535 50.1 62.94 50.0054C63.5076 49.9108 64.1509 50.0622 64.5104 50.5162C64.8509 50.9325 64.9077 51.5568 64.7563 52.0866C64.605 52.6164 64.3023 53.0894 63.9995 53.5434C63.6401 54.0164 63.2617 54.5462 62.8643 55.057Z'
				stroke='white'
				strokeWidth='2'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default LogoLoginBroqui;
