import React from 'react';

const LogoBroqui = () => {
	return (
		<svg
			width='51'
			height='51'
			viewBox='0 0 51 51'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<rect width='80' height='51' rx='25.5' />
			<path
				d='M20.5784 33.9456C20.5591 33.9939 20.5494 34.0421 20.5398 34.1C20.5301 34.1579 20.5108 34.2062 20.5012 34.2641C19.7871 34.6307 18.967 34.843 18.1082 34.843C15.2327 34.843 12.9073 32.5079 12.9073 29.6421C12.9073 28.9474 13.0424 28.2816 13.2933 27.6737C11.624 26.8053 10.4757 25.0588 10.4757 23.0517C10.4757 20.1763 12.8108 17.8509 15.6766 17.8509C16.5161 17.8509 17.2977 18.0438 18.002 18.4009C18.4942 14.8596 21.5336 12.1289 25.2196 12.1289C29.0117 12.1289 32.1284 15.0333 32.4758 18.7386C33.3056 18.1789 34.2995 17.8605 35.3705 17.8605C38.246 17.8605 40.5714 20.1956 40.5714 23.0614C40.5714 25.0781 39.4328 26.8246 37.7539 27.6833C38.0047 28.2912 38.1398 28.957 38.1398 29.6518C38.1398 32.5272 35.8047 34.8527 32.9389 34.8527C32.1188 34.8527 31.3372 34.6597 30.6424 34.322C30.6328 34.2641 30.6135 34.1965 30.5942 34.1386C30.5749 34.0807 30.5652 34.0228 30.5459 33.9649'
				stroke='white'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M32.0609 28.0787C31.617 28.6673 31.1635 29.2848 30.8741 29.912C30.5749 30.5682 30.7679 31.3401 30.7679 32.0541C30.7679 33.6463 30.0539 35.0647 28.9346 36.0199C29.3109 36.2998 29.5521 36.7533 29.5521 37.2647C29.5521 38.1235 28.8574 38.8278 27.989 38.8278C27.1302 38.8278 26.4258 38.1331 26.4258 37.2647C26.4258 37.2454 26.4258 37.2164 26.4258 37.1875C26.146 37.2357 25.8565 37.2647 25.5574 37.2647C25.3258 37.2647 25.0942 37.2454 24.8626 37.2164C24.8626 37.2357 24.8626 37.2454 24.8626 37.2647C24.8626 38.1235 24.1679 38.8278 23.2995 38.8278C22.4407 38.8278 21.7363 38.1331 21.7363 37.2647C21.7363 36.8015 21.9389 36.377 22.267 36.0971C22.1512 36.1936 21.196 34.9103 21.1188 34.7849C20.7907 34.2445 20.5591 33.6463 20.4433 33.0287C20.2503 32.0059 20.4626 30.9927 20.6363 29.9796C20.3082 29.4199 19.8644 28.9278 19.6328 28.3296C19.4012 27.7313 19.4784 26.9304 20.0188 26.5831C20.453 26.3032 21.0609 26.4094 21.4854 26.7182C21.9968 27.0945 22.1705 27.9629 22.7688 27.8278C22.8749 27.8085 24.5635 26.8243 24.5732 26.9497C24.3705 25.2515 24.2161 23.7559 25.0846 22.1831C25.239 21.9032 25.4319 21.6234 25.6925 21.4401C25.953 21.2567 26.31 21.1699 26.6091 21.2953C27.0723 21.498 27.2074 22.0866 27.2653 22.5883C27.4197 24.0453 27.5065 25.5603 27.5258 27.0076C28.2398 27.0269 28.9249 27.3936 29.3302 27.9822C29.8223 27.1427 30.4977 26.419 31.2986 25.869C31.5398 25.705 31.8004 25.5506 32.0995 25.5024C32.389 25.4541 32.717 25.5313 32.9004 25.7629C33.0741 25.9752 33.103 26.2936 33.0258 26.5638C32.9486 26.834 32.7942 27.0752 32.6398 27.3068C32.4565 27.548 32.2635 27.8182 32.0609 28.0787Z'
				stroke='white'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default LogoBroqui;
