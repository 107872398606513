import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export interface IAuthContextProps {
	user: string;
	userData: any;
	setUser: any;
	setUserData: any;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('authUsername') || '');
	const [userData, setUserData] = useState<string>(localStorage.getItem('authUserData') || '');

	useEffect(() => {
		const storedUserData = localStorage.getItem('authUserData') || '';

		if (storedUserData) {
			const obj = JSON.parse(storedUserData || '');
			if (obj) {
				setUserData(obj);
			}
		}
	}, []);

	const value = useMemo(
		() => ({
			user,
			setUser,
			setUserData,
			userData,
		}),
		[user, userData],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
