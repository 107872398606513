import { gql } from '@apollo/client';

export const GET_ELEMENTS = gql`
	query getElements($page: Int!, $limit: Int!) {
		getElements(page: $page, limit: $limit) {
			items {
				id
				imageUrl
				measurementUnit
				consumptionRate {
					fromPercent
					toPercent
				}
				langData {
					lang
					name
					description
					group
				}
			}
			total
		}
	}
`;

// {
// 	"limit": 50,
// 	"page": 1,
// }

export const GET_ELEMENT = gql`
	query getElement($id: String!) {
		getElement(id: $id) {
			imageUrl
			measurementUnit

			consumptionRate {
				fromPercent
				toPercent
			}
			langData {
				lang
				name
				description
				group
			}
		}
	}
`;

// {
// 	"id": "0187136b-eda4-71c6-b9c9-35304e415d76"
// }
