import React from 'react';
import Footer from '../../../layout/Footer/Footer';

const DefaultFooter = () => {
	const currentYear = new Date().getFullYear();
	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col text-center fs-6'>© {currentYear} | Broqui Admin Panel</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
