import { gql } from '@apollo/client';

export const GET_PRODUCT_TYPES = gql`
	query RootQuery($page: Int!, $limit: Int!) {
		getProductTypes(page: $page, limit: $limit) {
			items {
				id
				imageUrl
				langData {
					lang
					name
				}
			}
			total
		}
	}
`;

export const GET_PRODUCT_TYPE = gql`
	query RootQuery($id: String!) {
		getProductType(id: $id) {
			id
			imageUrl
			langData {
				lang
				name
			}
		}
	}
`;
