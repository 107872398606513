import { gql } from '@apollo/client';

export const GET_FAMILIES = gql`
	query getFamilies($page: Int!, $limit: Int!) {
		getFamilies(page: $page, limit: $limit) {
			items {
				id
				imageUrl
				recommendedMealTime
				color
				langData {
					lang
					name
				}
			}
			total
		}
	}
`;

export const GET_FAMILY = gql`
	query getFamily($id: String!) {
		getFamily(id: $id) {
			id
			imageUrl
			recommendedMealTime
			color
			langData {
				lang
				name
			}
		}
	}
`;
