import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	const storedUserToken = localStorage.getItem('authToken') || '';
	const path = window.location.pathname;
	const navigate = useNavigate();

	useEffect(() => {
		if (storedUserToken !== '' && path === '/login') {
			navigate('/');
		}
	}, []);

	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
