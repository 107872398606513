import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
	query RootQuery($page: Int!, $limit: Int!) {
		getProducts(page: $page, limit: $limit) {
			products {
				items {
					id
					imageUrl
					measurementUnit
					elementList {
						id
						quantity
					}
					familyId
					cuisineId
					productTypeId
					dish
					cuisine {
						id
						langData {
							name
							lang
						}
					}
					productType {
						id
						imageUrl
						langData {
							name
							lang
						}
					}
					measurementUnit
					healthBenefitsImageUrl
					recipeImageUrls {
						imageUrl
					}
					cookingTimeMinutes
					langData {
						lang
						growsPeriod
						countingTerms {
							name
							quantity
						}
						growsPlace
						recipe {
							description
							step
						}
						name
					}
				}
				total
			}
			cuisines {
				langData {
					lang
					name
				}
				id
			}
			productTypes {
				langData {
					lang
					name
				}
				id
				imageUrl
			}
			families {
				langData {
					lang
					name
				}
				id
				imageUrl
				recommendedMealTime
			}
		}
	}
`;

// {
// 	"limit": 50,
// 	"page": 1,
// 	"lang": "en"
// }

export const GET_PRODUCT = gql`
	query RootQuery($id: String!) {
		getProduct(id: $id) {
			product {
				id
				imageUrl
				measurementUnit
				elementList {
					id
					quantity
				}
				familyId
				cuisineId
				productTypeId
				dish
				cuisine {
					id
					langData {
						name
						lang
					}
				}
				productType {
					id
					langData {
						name
						lang
					}
				}
				measurementUnit
				healthBenefitsImageUrl
				recipeImageUrls {
					imageUrl
				}
				cookingTimeMinutes
				langData {
					lang
					growsPeriod
					countingTerms {
						name
						quantity
					}
					growsPlace
					recipe {
						description
						step
					}
					name
				}
			}
			cuisines {
				langData {
					lang
					name
				}
				id
			}
			productTypes {
				langData {
					lang
					name
				}
				id
				imageUrl
			}
			families {
				langData {
					lang
					name
				}
				id
				imageUrl
				recommendedMealTime
			}
		}
	}
`;

// {
// 	"id": "0187136b-eda4-71c6-b9c9-35304e415d76"
// }
