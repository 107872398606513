import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
	query authAsAdmin($email: String!, $password: String!) {
		authAsAdmin(email: $email, password: $password) {
			token
			user {
				id
				email
				firstName
				lastName
				role
			}
		}
	}
`;
