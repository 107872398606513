import React, { lazy } from 'react';
import { dashboardPagesMenu, pagesMenu, componentsMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	LIST_OF_ELEMENTS: lazy(() => import('../pages/presentation/elements/ElementsListPage')),
	ELEMENT: lazy(() => import('../pages/presentation/elements/ElementPage')),
	LIST_OF_PRODUCTS: lazy(() => import('../pages/presentation/products/ProductsListPage')),
	LIST_OF_COOKING_TYPES: lazy(
		() => import('../pages/presentation/archive/cooking-types/CookingTypesListPage'),
	),
	COOKING_TYPE: lazy(() => import('../pages/presentation/archive/cooking-types/CookingTypePage')),
	LIST_OF_CUISINES: lazy(() => import('../pages/presentation/cuisines/CuisinesListPage')),
	CUISINE: lazy(() => import('../pages/presentation/cuisines/CuisinePage')),
	LIST_OF_FAMILIES: lazy(() => import('../pages/presentation/families/FamiliesListPage')),
	FAMILY: lazy(() => import('../pages/presentation/families/FamilyPage')),
	LIST_OF_PRODUCT_TYPES: lazy(
		() => import('../pages/presentation/productTypes/ProductTypesListPage'),
	),
	PRODUCT_TYPE: lazy(() => import('../pages/presentation/productTypes/ProductTypePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: pagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: pagesMenu.login.path,
		element: <Login />,
	},

	{
		path: pagesMenu.element.path,
		element: <PAGE_LAYOUTS.ELEMENT />,
	},

	{
		path: pagesMenu.cookingType.path,
		element: <PAGE_LAYOUTS.COOKING_TYPE />,
	},

	{
		path: pagesMenu.cuisine.path,
		element: <PAGE_LAYOUTS.CUISINE />,
	},

	{
		path: pagesMenu.family.path,
		element: <PAGE_LAYOUTS.FAMILY />,
	},

	{
		path: pagesMenu.productType.path,
		element: <PAGE_LAYOUTS.PRODUCT_TYPE />,
	},

	// {
	// 	path: pagesMenu.,
	// 	element: <PAGE_LAYOUTS.FAMILY />,
	// },

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: componentsMenu.elementsLayout.subMenu.listOfElements.path,
		element: <PAGE_LAYOUTS.LIST_OF_ELEMENTS />,
	},
	{
		path: componentsMenu.productsLayout.subMenu.listOfProducts.path,
		element: <PAGE_LAYOUTS.LIST_OF_PRODUCTS />,
	},
	// {
	// 	path: componentsMenu.productsAdditionalTablesLayout.subMenu.listOfCookingTypes.path,
	// 	element: <PAGE_LAYOUTS.LIST_OF_COOKING_TYPES />,
	// },
	{
		path: componentsMenu.productsAdditionalTablesLayout.subMenu.listOfFamilies.path,
		element: <PAGE_LAYOUTS.LIST_OF_FAMILIES />,
	},
	{
		path: componentsMenu.productsAdditionalTablesLayout.subMenu.listOfCuisines.path,
		element: <PAGE_LAYOUTS.LIST_OF_CUISINES />,
	},
	{
		path: componentsMenu.productsAdditionalTablesLayout.subMenu.listOfProductTypes.path,
		element: <PAGE_LAYOUTS.LIST_OF_PRODUCT_TYPES />,
	},
];
const contents = [...presentation];

export default contents;
