import { gql } from '@apollo/client';

export const GET_COOKING_TYPES = gql`
	query getCookingTypes($page: Int!, $limit: Int!) {
		getCookingTypes(page: $page, limit: $limit) {
			items {
				id
				langData {
					lang
					name
				}
			}
			total
		}
	}
`;

// {
// 	"limit": 50,
// 	"page": 1,
// 	"lang": "fr"
// }

export const GET_COOKING_TYPE = gql`
	query getCookingType($id: String!) {
		getCookingType(id: $id) {
			langData {
				lang
				name
			}
		}
	}
`;

// {
// 	"id": "01877ff0-1cad-7595-872a-07d70be25af8"
// }
