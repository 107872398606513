import { gql } from '@apollo/client';

export const GET_CUISINES = gql`
	query getCuisines($page: Int!, $limit: Int!) {
		getCuisines(page: $page, limit: $limit) {
			items {
				id
				langData {
					lang
					name
				}
			}
			total
		}
	}
`;

// {
// 	"limit": 50,
// 	"page": 1,
// 	"lang": "fr"
// }

export const GET_CUISINE = gql`
	query getCuisine($id: String!) {
		getCuisine(id: $id) {
			langData {
				lang
				name
			}
		}
	}
`;

// {
// 	"id": "01877ff0-1cad-7595-872a-07d70be25af8"
// }
