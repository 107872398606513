import React, { FC, ReactNode, useContext, useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/authContext';
import { useHotkeys } from 'react-hotkeys-hook';

import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';

import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import Avatar from '../../../components/Avatar';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [state, setState] = useState<boolean>(false);

	const { userData } = useContext(AuthContext);

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	const { i18n } = useTranslation();

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then();
	};

	/**
	 * Language attribute
	 */
	useEffect(() => {
		if (typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon === 'undefined') {
			changeLanguage('fr' as ILang['key']['lng']);
		}
	}, []);

	useHotkeys('control+t', () => {
		if (i18n.language === 'fr') {
			changeLanguage('en' as ILang['key']['lng']);
		} else {
			changeLanguage('fr' as ILang['key']['lng']);
		}
	});

	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	// Функция для смены языка

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					{typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
					'undefined' ? (
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							className='btn-only-icon'
							aria-label='Change language'
							data-tour='lang-selector'
							onClick={() => changeLanguage('fr')}>
							<Spinner isSmall inButton='onlyIcon' isGrow />
						</Button>
					) : (
						<>
							{Object.keys(LANG).map((i) => (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									className='btn-only-icon'
									aria-label='Change language'
									data-tour='lang-selector'
									key={LANG[i].lng}
									icon={LANG[i].icon}
									onClick={() => changeLanguage(LANG[i].lng)}
									isDisable={i18n.language === LANG[i].lng}></Button>
							))}
						</>
					)}
				</div>

				<div
					className='col d-flex align-items-center cursor-pointer'
					onClick={() => setState(!state)}
					role='presentation'>
					<div className='me-3'>
						<div className='text-end'>
							<div className='fw-bold fs-6 mb-0'>
								{`${userData.name} ${userData.surname}`}
							</div>
							<div className='text-muted'>
								<small>{userData.position}</small>
							</div>
						</div>
					</div>
					<div className='position-relative'>
						<Avatar
							srcSet='/images/avatar/avatar.jpg'
							src='/images/avatar/avatar.jpg'
							size={48}
						/>
					</div>
				</div>

				{afterChildren}
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
