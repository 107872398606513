// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-shadow */
/** Core */
import React, { FC, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

/** Apollo */
import { useLazyQuery } from '@apollo/client';
import { LOGIN_USER } from '../../../../src/services/queries';

/** Modules */
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';

import UserImage from '../../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../../assets/img/wanna/wanna1.webp';

import Spinner from '../../../components/bootstrap/Spinner';

import LogoLoginBroqui from '../../../components/LogoLoginBroqui';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = () => {
	const { setUser, setUserData } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	const [authAsAdmin, { loading }] = useLazyQuery(LOGIN_USER);

	const validate = (values: any) => {
		const errors: { username?: string; password?: string } = {};

		if (!values.username) {
			errors.username = 'Required';
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username) &&
			!/^[a-zA-Z]+$/i.test(values.username)
		) {
			errors.username = 'Invalid username or email';
		}

		if (!values.password) {
			errors.password = 'Required';
		} else if (values.password.length < 3 || values.password.length > 30) {
			errors.password = 'Password must be between 3 and 30 characters';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validate,
		onSubmit: async (values) => {
			await authAsAdmin({
				variables: {
					email: formik.values.username,
					password: formik.values.password,
				},
			})
				.then((res) => {
					if (res.data?.authAsAdmin) {
						const data = res.data.authAsAdmin;
						let user = {
							id: data.user.id,
							username: data.user.firstName,
							name: data.user.firstName,
							surname: data.user.lastName,
							position: data.user.role === 'ROLE_ADMIN' ? 'Admin' : 'User',
							email: data.user.email,
							src: UserImage,
							srcSet: UserImageWebp,
							isOnline: true,
							isReply: true,
							color: 'primary',
							services: [],
							password: '',
						};

						setUserData(user);
						setUser(data.user.firstName);

						localStorage.setItem('authUsername', data.user.firstName);
						localStorage.setItem('authUserData', JSON.stringify(user));
						localStorage.setItem('authToken', data.token);

						navigate('/');
					} else {
						navigate('/login');
					}
				})
				.catch((error: any) => {
					console.log(error);
				});
		},
	});

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className={classNames({ 'bg-dark': true, 'bg-light': false })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<LogoLoginBroqui />
									</Link>
								</div>

								<LoginHeader />

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<FormGroup
												id='username'
												isFloating
												label='Your email or username'
												className={'mb-4'}>
												<Input
													id='username'
													name='username'
													type='text'
													autoComplete='username'
													value={formik.values.username}
													isTouched={formik.touched.username}
													invalidFeedback={formik.errors.username}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													onFocus={() => {
														formik.setErrors({});
													}}
												/>
											</FormGroup>
											<FormGroup id='password' isFloating label='Password'>
												<Input
													id='password'
													name='password'
													type='password'
													autoComplete='current-password'
													value={formik.values.password}
													isTouched={formik.touched.password}
													invalidFeedback={formik.errors.password}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={formik.handleSubmit}>
												{loading && <Spinner isSmall inButton isGrow />}
												Login
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
