export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const pagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},

	element: {
		id: 'element',
		text: 'Element',
		path: 'components/element/:id',
		icon: 'Article',
	},

	product: {
		id: 'product',
		text: 'Product',
		path: 'components/product/:id',
		icon: 'Article',
	},

	cookingType: {
		id: 'cookingType',
		text: 'Cooking Type',
		path: 'components/cooking-type/:id',
		icon: 'Article',
	},

	family: {
		id: 'family',
		text: 'Family',
		path: 'components/family/:id',
		icon: 'Article',
	},

	cuisine: {
		id: 'cuisine',
		text: 'Cuisine',
		path: 'components/cuisine/:id',
		icon: 'Article',
	},

	productType: {
		id: 'product-type',
		text: 'Product Type',
		path: 'components/product-types/:id',
		icon: 'Article',
	},
};

export const componentsMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Main Context',
	},

	productsLayout: {
		id: 'productsLayout',
		text: 'Products & Dishes',
		path: 'products_dishes',
		icon: 'BackupTable',
		subMenu: {
			listOfProducts: {
				id: 'list-of-products',
				text: 'List of Products & Dishes',
				path: '/products/list-of-products-dishes',
				icon: 'List',
			},
		},
	},

	elementsLayout: {
		id: 'elementsLayout',
		text: 'Elements',
		path: 'elements',
		icon: 'BackupTable',
		subMenu: {
			listOfElements: {
				id: 'list-of-elements',
				text: 'List of Elements',
				path: '/components/list-of-elements',
				icon: 'ViewList',
			},
		},
	},

	// cookingTypesLayout: {
	// 	id: 'cookingTypesLayout',
	// 	text: 'Cooking Types',
	// 	path: 'cooking-types',
	// 	icon: 'BackupTable',
	// 	subMenu: {
	// 		listOfCookingTypes: {
	// 			id: 'list-of-cooking-types',
	// 			text: 'List of Cooking Types',
	// 			path: '/cooking-types/list-of-cooking-types',
	// 			icon: 'List',
	// 		},
	// 	},
	// },

	// familiesLayout: {
	// 	id: 'familiesLayout',
	// 	text: 'Families',
	// 	path: 'families',
	// 	icon: 'BackupTable',
	// 	subMenu: {
	// 		listOfFamilies: {
	// 			id: 'list-of-families',
	// 			text: 'List of Families',
	// 			path: '/families/list-of-families',
	// 			icon: 'List',
	// 		},
	// 	},
	// },

	productsAdditionalTablesLayout: {
		id: 'additional-tables-layout',
		text: 'Additional Items',
		path: 'products-additional-items',
		icon: 'BackupTable',
		subMenu: {
			// listOfCookingTypes: {
			// 	id: 'list-of-cooking-types',
			// 	text: 'Cooking Types',
			// 	path: '/cooking-types/list-of-cooking-types',
			// 	icon: 'List',
			// },
			listOfFamilies: {
				id: 'list-of-families',
				text: 'Families',
				path: '/families/list-of-families',
				icon: 'List',
			},
			listOfCuisines: {
				id: 'list-of-cuisines',
				text: 'Cuisines',
				path: '/families/list-of-cuisines',
				icon: 'List',
			},
			listOfProductTypes: {
				id: 'list-of-product-types',
				text: 'Product Types',
				path: '/product-types/list-of-product-types',
				icon: 'List',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
